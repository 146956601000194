// 考试
<template>
  <div class="exam">
    <div class="exam_box margin-auto w-1200">
      <div class="exam_tag flexs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>考试</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="exam_head flex p-l-20 p-r-20">
        <div class="exam_head_name">2020年终绩效考试</div>
        <div class="exam_head_time flexs">
          <span>剩余时间：</span>
          <span>{{ time }}</span>
        </div>
      </div>
      <div class="exam_main m-t-20 flex">
        <div class="exam_main_left b-c-f">
          <div class="exam_main_head flexs">
            <span>选择题</span>
          </div>
          <ul class="exam_main_list p-b-40 p-l-20 p-r-20">
            <li v-for="(item, index) in topicList" :key="index">
              <div class="exam_main_list_name">
                {{ index + 1 }}、{{ item.ti }}（）
              </div>
              <div class="exam_main_list_ul">
                <div
                  class="exam_main_list_li flexs"
                  @click="selectTopic(item, index, ind, em)"
                  v-for="(em, ind) in item.topic"
                  :key="ind"
                >
                  <div
                    class="exam_main_list_checked center m-r-20"
                    :class="{ active: item.checked.indexOf(ind) != -1 }"
                  >
                    {{ getA(ind) }}
                  </div>
                  <div class="exam_main_list_txt">{{ em }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="exam_main_right b-c-f p-b-40">
          <div class="exam_main_head flexs">
            <span>答题卡</span>
          </div>
          <ul class="exam_main_right_ul flexs flex-wrap">
            <li
              class="center"
              :class="{ active: item.checked.length != 0 }"
              v-for="(item, index) in topicList"
              :key="index"
            >
              {{ index + 1 }}
            </li>
          </ul>
          <div class="submitRoll center m-t-10" @click="submitTopic">
            <img src="../../assets/image/microlecture/roll.png" alt="" />
            <span>我要交卷</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 是否提交 S -->
    <submit-exam ref="select" @select="changeSelect"></submit-exam>
    <!-- 是否提交 E -->
  </div>
</template>

<script>
import SubmitExam from "../../components/SubmitExam.vue";
export default {
  components: { SubmitExam },
  data() {
    return {
      id: null, //视频id
      time:'00分00秒',
      topicList: [], //题目列表
      clearTime:null,
      totalSeconds:0,//
      when:'',//用时
    };
  },
  methods: {
    changeSelect(index) {
      if (index == 1) {
        let id = [];
        let answer = [];
        this.topicList.forEach((item) => {
          id.push(item.id);

          let data = [];
          item.checked.sort().forEach((em) => {
            data.push(this.getA(em));
          });
          answer.push(data.join("/"));
        });
        setTimeout(() => {
          this.$router.replace({
            path: "/examResult",
            query: {
              id: id.join(","),
              answer: answer.join(","),
              time: this.when,
            },
          });
        }, 800);
      }
    },
    //提交题目
    submitTopic() {
      clearInterval(this.clearTime);
      this.when = this.timeFun(3600 - this.totalSeconds,2)
      if (this.topicList.some((em) => em.checked.length == 0))
        return this.$message({
          message: "所有题目答完才能提交!",
          type: "warning",
        });
      this.$refs.select.open();
    },
    //选择题目
    selectTopic(item, index, ind) {
      //type1单选2多选
      if (item.type == 1) {
        item.checked = [];
        item.checked.push(ind);
      } else {
        if (item.checked.indexOf(ind) != -1) {
          item.checked.splice(item.checked.indexOf(ind), 1);
        } else {
          item.checked.push(ind);
        }
      }
    },
    //获取考试列表
    getKaoShiDetail() {
      this.$axios
        .kaoShiDetail({
          id: this.id,
          pageNum: 1000,
        })
        .then((res) => {
          res.data.data.forEach((element) => {
            element.topic = [];
            element.checked = [];
            element.ti1.forEach((em) => {
              if (em) {
                element.topic.push(em);
              }
            });
          });
          this.topicList = res.data.data;
        });
    },
    //获取类型
    getA(index) {
      // eslint-disable-next-line no-unused-vars
      let txt = "";
      switch (index) {
        case 0:
          txt = "A";
          break;
        case 1:
          txt = "B";
          break;
        case 2:
          txt = "C";
          break;
        case 3:
          txt = "D";
          break;
        case 4:
          txt = "E";
          break;
        case 5:
          txt = "F";
          break;
        default:
          break;
      }
      return txt;
    },
    timeFun(totalSeconds,type) {
      var days = Math.floor(totalSeconds / (60 * 60 * 24)); //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24); //小时数
      var hours = Math.floor(modulo / (60 * 60));
      if (days > 0) {
        hours = hours + days * 24;
      }
      modulo = modulo % (60 * 60); //分钟
      var minutes = Math.floor(modulo / 60); //秒
      var seconds = modulo % 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (type == 1) {
        return minutes + "分" + seconds + '秒';
      } else {
        return minutes + ":" + seconds;
      }
      
    }, //倒计时
    showTime(totalSeconds) {
     this.clearTime = setInterval(() => {
      if (totalSeconds == 0) {
        clearInterval(this.clearTime);
      } else {
        totalSeconds--;
        this.time = this.timeFun(totalSeconds,1);
        this.totalSeconds = totalSeconds
      }
    }, 1000);
    },
    kaoShi () {
      this.$axios.kaoShi().then(res=>{
        this.showTime(res.data.time * 60)
      })
    }
  },
  created() {
    
    this.kaoShi()
    this.id = this.$route.query.id;
    this.getKaoShiDetail();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-breadcrumb__inner {
  color: #3273f6 !important;
}
/deep/ .is-link {
  color: #666666 !important;
}
.exam_tag {
  height: 52px;
}
.exam_head {
  height: 88px;
  background: #ffffff;
  border-top: 6px solid #82abfe;
  .exam_head_name {
    color: #333333;
    font-size: 22px;
    font-weight: bold;
  }
  .exam_head_time {
    span {
      color: #333333;
      font-size: 18px;
      &:last-child {
        color: #ff2e2e;
        font-size: 24px;
      }
    }
  }
}
.exam_main_head {
  height: 64px;
  padding-left: 34px;
  border-bottom: 1px dashed #cccccc;
  span {
    position: relative;
    &::after {
      content: "";
      left: -14px;
      width: 4px;
      height: 24px;
      background: #3273f6;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.exam_main {
  align-items: flex-start;
}
.exam_main_left {
  width: 820px;
  border-radius: 4px;
}
.exam_main_right {
  width: 360px;
  border-radius: 4px;
}
.exam_main_list {
  li {
    margin-top: 30px;
    .exam_main_list_name {
      color: #333333;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .exam_main_list_ul {
      .exam_main_list_li {
        cursor: pointer;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .exam_main_list_checked {
          width: 32px;
          height: 32px;
          color: #333333;
          font-size: 20px;
          background: #f3f7ff;
          border-radius: 50%;
        }
        .active {
          color: #ffffff;
          background: #82abfe;
        }
      }
    }
  }
}
.exam_main_right_ul {
  padding: 30px 34px 0px 34px;
  li {
    color: #666666;
    width: 32px;
    height: 32px;
    font-size: 18px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    margin-right: 20px;
    margin-bottom: 30px;
    &:nth-child(6n) {
      margin-right: 0;
    }
  }
  .active {
    color: #ffffff;
    background: #82abfe;
    border: none;
  }
}
.submitRoll {
  width: 280px;
  height: 56px;
  margin: 0 auto;
  cursor: pointer;
  background: linear-gradient(90deg, #3273f6, #82abfe);
  border-radius: 28px;
  img {
    width: 32px;
    height: 32px;
    margin-right: 18px;
  }
  span {
    color: #ffffff;
    font-size: 18px;
  }
}
</style>
