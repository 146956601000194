<template>
  <div class="submitExam">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="480px"
      :close-on-click-modal="false"
      :show-close="false"
    >
    <div class="submitExam_box">
      <div class="submitExam_close" @click="dialogVisible = false">
        <img src="../assets/image/publice/close.png" alt="">
      </div>
      <div class="submitExam_main center">
        <img src="../assets/image/microlecture/steamed.png" alt="">
        <span>确认交卷？</span>
      </div>
      <ul class="submitExam_ul flex">
        <li @click="changeFooter(index)" class="center" v-for="(item,index) in 2" :key="index">{{ index == 0 ? '我再想想' : '确认交卷' }}</li>
      </ul>
    </div>
      
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,//
    }
  },
  methods:{
    changeFooter (index) {
      this.$emit('select',index)
      this.dialogVisible = false
    },
    open () {
      this.dialogVisible = true
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header ,/deep/ .el-dialog__body {
  padding: 0;
}
.submitExam_box {
  position: relative;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 60px 40px;
  .submitExam_close {
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
  }
  .submitExam_main {
    margin-bottom: 60px;
    flex-direction: column;
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 40px;
    }
    span {
      color: #333333;
      font-size: 24px;
    }
  }
  .submitExam_ul {
    li {
      color: #3273F6;
      font-size: 18px;
      cursor: pointer;
      width: 180px;
      height: 48px;
      border: 1px solid #3273F6;
      border-radius: 4px;
      &:last-child {
        color: #FFFFFF;
        background: #3273F6;
      }
    }
  }
}
</style>
